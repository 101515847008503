module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://datawisp.matomo.cloud","siteUrl":"https://datawisp.io"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"datawisp.io"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
